/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/

@use "uswds-core" as *;
@import '_components/side_nav.scss';
@import '_components/home.scss';
@import '_components/inline_form.scss';

html, body {
   height: 100%;
   line-height: line-height('body', 6);
   scroll-behavior: smooth;
}

.flex-shrink-0 {
   flex-shrink: 0;
}

#app {
   display: flex;
   flex-direction: column;
   height: 100%;
   flex-wrap: nowrap;
}

.flex-fill {
   flex: 1 0 auto;
}

.usa-intro {
   @include u-font('sans', $theme-type-scale-lg);
   @include u-color('base-darker');
}

/* global h4 headings are sans */
 
h4 {
   @include u-font('sans', $theme-type-scale-sm)
}

.usa-prose {
   h4 {
      @include u-font('sans', $theme-type-scale-sm)
   }

   hr {
      border-color: color('base-lighter')
   }
}
// below to resolve the mobile issue for full size template page ( without side nav)
@media screen and (max-width:768px) {
   body{
      width:fit-content;
   }
}

// below is to make usa-prose max-width to fits content for pages without sidenav (Full size template)
.full-size-template {
   .usa-prose>p{
      max-width:fit-content;
   }
}
