.usa-sidenav {
    .usa-sidenav__item {

        background-color: color('base-lightest');
        &:hover {
            background-color: color('base');
            color: color('base-lightest');
        }
    }
    a:not(.usa-button):hover {
        background-color: color('base');

    }
    a:not(.usa-button):not(.usa-current) {
        color: color('primary-vivid');
        &:hover {
            color: color('base-lightest');
        }
    }

    // used for FAQ page that doesn't have usa-current but have sub menu

    a:not(:hover):not(.usa-current) {

        background-color:color('base-lightest');
    }
    .usa-sidenav__item.current {
        background-color: color('base');
        &:hover {
            color: color('base-lightest')
        }
    }
    .usa-current {
        color: color('base-lightest');
        font-weight: normal;
    }
}
.sp_site_nav {
    // let the sidebar sticky to the top left when screen are scrolling
    position: sticky;
    top: 0;
    div.sp_site_nav_title {
        margin-top:0;
        padding: 1em 0 1em .5em;
        border-top: 2px solid color('base-light');
        color: color('base');
        font-weight: bold;
    }
}


