// inline form with adjacent button

.usa-form.sp-inline-form {
    display: flex;
    flex-direction: row;
    @include u-margin-top(0);
  }
  .usa-form .sp-inline-form-input {
    flex: 0 1 auto;
  }
  .usa-form .sp-inline-form-submit-button {
    flex: 1 1 0%;
    @include u-margin-top(1);
    @include u-margin-left(1);
  }